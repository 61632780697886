<template>
    <div class="reportPopup" :v-if="reportVisible">
        <div class="box innerBox">
            <div class="popTitle popTitleArea">
                <p>보고서 다운로드</p>
                <div class="buttonArea">
                    <button class="btn solid small downloadBtn" @click="downloadReport($event)" v-ripple>
                        <i class="micon">file_download</i> 파일 다운로드
                    </button>
                    <button class="x" @click="closeReport()">
                        <i class="micon">close</i>
                    </button>
                </div>
            </div>

            <div class="reportWrapper">
                <div id="report" ref="printDoc" :key="pdfKey">
                    <ul class="wrap">
                        <!--1-->
                        <li class="page page1">
                            <div class="titW">
                                <p class="year"></p>
                                <p class="pTitle">Pine-DXS 보고서</p>
                            </div>
                            <ul class="patiInfo">
                                <li>
                                    <p class="tit">이름</p>
                                    <p class="txt">{{ patient.name }}</p>
                                </li>
                                <li>
                                    <p class="tit">생년월일</p>
                                    <p class="txt">{{ patient.birth }}</p>
                                </li>
                                <li>
                                    <p class="tit">성별</p>
                                    <p class="txt">{{ patient.sexStr }}</p>
                                </li>
                                <li>
                                    <p class="tit">환자번호</p>
                                    <p class="txt">{{ patient.patientSn }}</p>
                                </li>
                                <li>
                                    <p class="tit">촬영일</p>
                                    <p class="txt">{{ record.current.pictureDtStr }}</p>
                                </li>
                            </ul>
                            <div class="logoArea">
                                <img src="@/assets/images/colorLogo.svg" alt="Pine-DXS" />
                            </div>
                        </li>
                        <!--2-->
                        <li class="page page2">
                            <p class="styleTit">통합 분석 결과</p>
                            <ul class="box normal">
                                <li>
                                    <div class="flexRows">
                                        <div class="cols cobbImgCols">
                                            <img :src="record.current.spineImgUrl">
                                        </div>
                                        <div class="cols infoCols">
                                            <ul class="risserResultCols">
                                                <li>
                                                    <p class="graphTitle">촬영일</p>
                                                    <div class="resultValue">{{record.current.pictureDtStr}}</div>
                                                </li>
                                            </ul>
                                            <p class="graphTitle">Cobb's angle</p>
                                            <div class="tblBg2">
                                                <table class="cobbTbl">
                                                    <colgroup>
                                                        <col style="width:10px;">
                                                        <col style="width:40%;">
                                                        <col />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>&nbsp;</th>
                                                            <th>Cobb's angle</th>
                                                            <th>휜 방향</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>1</th>
                                                            <td>{{record.current.firstAngle }} º</td>
                                                            <td>{{record.current.firstDirection}}</td>
                                                        </tr>
                                                        <tr v-if="record.current.secondAngle">
                                                            <th>2</th>
                                                            <td>{{record.current.secondAngle }} º</td>
                                                            <td>{{record.current.secondDirection}}</td>
                                                        </tr>
                                                        <tr v-if="record.current.thirdAngle">
                                                            <th>3</th>
                                                            <td>{{record.current.thirdAngle }} º</td>
                                                            <td>{{record.current.thirdDirection}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ul class="risserResultCols">
                                                <li>
                                                    <p class="graphTitle">Risser grade</p>
                                                    <div class="resultValue">{{record.current.risserGrade}}</div>
                                                </li>
                                                <li>
                                                    <p class="graphTitle">생리학적 나이</p>
                                                    <div class="resultValue">{{ record.current.atTimeAge }}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="risserImgCols">
                                        <img :src="record.current.risserImgUrl">
                                    </div>
                                </li>
                            </ul>
                            <div class="page-marker">-2-</div>
                        </li>
                        <!--3-->
                        <li class="page page3" v-if="record.firstSpine">
                            <p class="styleTit">이전 분석 결과</p>
                            <ul class="box normal">
                                <!--척추측만증 분석 결과-->
                                <li>
                                    <p class="boxTit">AI 기반 Cobb’s angle 분석 결과</p>
                                    <SpineCompo :record="record" :allImageLoaded="true"
                                        :isFirstRecord="record.isFirstSpine" :isReport="true" />
                                </li>
                                <!--//척추측만증 분석 결과-->
                                <!--뼈나이측정결과-->
                                <li class="risserArea">
                                    <p class="boxTit">AI 기반 {{ READING_RISSER_NAME }} 분석 결과</p>
                                    <RisserCompo :record="record" :allImageLoaded="true"
                                        :isFirstRecord="record.isFirstSpine" :isReport="true" />
                                </li>
                                <!--//뼈나이측정결과-->
                            </ul>
                            <div class="page-marker">-3-</div>
                        </li>
                        <!--4-->
                        <li class="page page4">
                            <p class="styleTit">AI 분석 결과 추이</p>
                            <ul class="box normal">
                                <li class="halfPage">
                                    <p class="graphTitle">Cobb's angle 분석 추이 그래프</p>
                                    <div class="graphContainer">
                                        <GraphCompo :xrayType="'spine'" :xrayNo="xrayNo" :width="'100%'" :height="'350px'" :isReport="true" graphType="maxAngle" v-if="isParentLoaded" />
                                    </div>
                                </li>
                                <li class="halfPage">
                                    <p class="graphTitle">Risser grade 분석 추이 그래프</p>
                                    <div class="graphContainer">
                                        <GraphCompo :xrayType="'hands'" :xrayNo="xrayNo" :width="'100%'" :height="'350px'" :isReport="true" graphType="risser" v-if="isParentLoaded" />
                                    </div>
                                </li>
                            </ul>
                            <div class="page-marker">{{ record.firstSpine? '-4-':'-3-' }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API_REPORT from '@/API/record/report'
import spineRsltCompo from '@/components/spineRsltCompo.vue';
import risserRsltCompo from '@/components/risserRsltCompo.vue';

export default {
    components: {
        GraphCompo: () => import("@/components/graphCompo.vue"),
        SpineCompo: () => import("@/components/spineReportCompo.vue"),
        RisserCompo: () => import("@/components/risserReportCompo.vue"),
        spineRsltCompo,
        risserRsltCompo,
    },
    props: {
        reportVisible: {
            default: false
        },
        onClick: {
            default: () => { }
        },
        xrayNo: {
            default: ''
        },
        xrayMap: {
            default() {
                return {
                    spine: {},
                    hands: {},
                };
            },
        },
        isReport: {
            default: true
        }
    },
    data() {
        return {
            crtYear: '',
            today: '',
            isGettingItems: false,
            patient: {},
            record: {
                current: {},
                firstSpine: {},
                firstHands: {},
                prev: {},
                isFirstSpine: false,
                isFirstHands: false,
            },
            pdfKey: 0,
            isParentLoaded: false,
        };
    },
    watch: {},
    created() {
        this.setPatientInfo();
    },
    async mounted() {
        await this.initData(this.xrayNo)
        this.getDate()
        this.isParentLoaded = true;
    },
    destroyed() {},
    methods: {
        getDate() {
            let today = new Date();
            let year = today.getFullYear();
            let month = String(today.getMonth() + 1).padStart(2, '0');
            let day = String(today.getDate()).padStart(2, '0');
            this.crtYear = year;
            this.today = year + month + day;
        },
        async initData(xrayNo) {
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let reportRes = await API_REPORT.request(xrayNo);
            if (reportRes.isSuccess) {
                this.patient = reportRes.patient;
                this.record.current = reportRes.currentRecord;
                this.record.isFirstSpine = reportRes.isFirstSpine;
                this.record.firstSpine = reportRes.firstSpineRecord;
                this.record.isFirstHands = reportRes.isFirstHands;
                this.record.firstHands = reportRes.firstHandsRecord;
                this.record.prev = reportRes.prevRecord;

                this.roundAngles(this.record.current);
                this.roundAngles(this.record.firstSpine);
                this.roundAngles(this.record.prev);

                if(this.record.firstSpine) this.record.firstSpine.atTimeAge = this.calculatedAgeAtFirstAnalysis(this.patient.birth, this.record.firstSpine.pictureDtStr);
                if(this.record.prev) this.record.prev.atTimeAge = this.calculatedAgeAtFirstAnalysis(this.patient.birth, this.record.prev.pictureDtStr);
                if(this.record.current) this.record.current.atTimeAge = this.calculatedAgeAtFirstAnalysis(this.patient.birth, this.record.current.pictureDtStr);

            } else {
                this.showPopup(reportRes.errorMsg, reportRes.status);
            }
            this.isGettingItems = false;
        },
        setPatientInfo() {
            const patientInfo = JSON.parse(localStorage.getItem('targetInfo'));
            if (patientInfo) {
                this.patient.patientNo = patientInfo.patientSn;
                this.patient.name = patientInfo.name;
                this.patient.birthDate = patientInfo.birth;
                this.patient.physiologicalAge = patientInfo.age;
                this.patient.gender = patientInfo.sex === 0 ? '남' : patientInfo.sex === 1 ? '여' : '';
            }
        },
        resetData() {
            this.record = {
                isFirstSpine: true,
                firstSpine: '',
                isFirstHands: true,
                firstHands: '',
                current: '',
                prev: ''
            }
            this.values = {
                patientNo: '',
                xrayNo: ''
            }
            this.patient = {}
        },
        closeReport() {
            this.resetData();
            this.isGettingItems = false;
            this.$emit('closeReport');
        },
        downloadReport(event) {
            if (event) event.preventDefault();
            if (this.isGettingItems) {
                const unwatch = this.$watch('isGettingItems', (newValue) => { // 데이터가 로드 중인 경우: 로딩 완료 후 다운로드 실행
                    if (!newValue) {
                        this.$htmlToPdf(this.$refs.printDoc, `[Pine-DXS] ${this.patient.name} 리포트_${this.today}`);
                        this.pdfKey += 1;
                        unwatch(); // 감시 중지
                    }
                });
            } else { // 데이터가 이미 로드된 경우: 즉시 다운로드 실행
                this.$htmlToPdf(this.$refs.printDoc, `[Pine-DXS] ${this.patient.name} 리포트_${this.today}`);
                this.pdfKey += 1;
            }
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.reportPopup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);

    .innerBox {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        max-width: 800px;
        border-radius: 16px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        max-height: 95vh;

        .popTitle {
            padding: 24px;
            font-size: 2.0rem;
            font-weight: 600;
            border-bottom: 1px solid #dbdbdb;

            &.popTitleArea {
                padding: 16px 24px !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .buttonArea {
                min-width: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .downloadBtn {
                    i {
                        font-size: 1.5rem;
                    }
                }

                .x {
                    position: absolute;
                    right: 24px;
                    top: 20px;
                }
            }
        }
    }
}

.reportWrapper {
    max-height: 800px;
    overflow: auto;

    #report {
        overflow: hidden;
        li {
            position: relative;
            &.page {
                clear: both;
                page-break-after: always;
            }
            &.page2, &.page3, &.page4 {
                //background-color: #f9f9f9;
                border-bottom:1px solid #dbdbdb;
                .page-marker {
                    text-align: center;
                    font-size: 1rem;
                    font-weight: bold;
                    position: absolute;
                    bottom:30px;
                    left:0;
                    right:0;
                }
            }
        }
        
    }
}
#report li{position: relative;}

.page-marker {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    bottom:30px;
    left:0;
    right:0;
}

.refText {
    display: inline-block;
    font-size: 9px;
    margin-top: 10px;
    color: #666;
    line-height: 1.5rem;
    word-break: keep-all;
}

.halfPage {
    // height: calc(50% - 40px); /* 페이지의 반을 차지하도록 설정 */
    padding: 10px;
    box-sizing: border-box;
}

.graphBox {
    .graphTitle {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-weight: bold;
        width: 100%;
    }
    .graphContainer {
        height: 100%; /* 부모 요소의 높이를 100% 차지하도록 설정 */
        padding: 10px;
        border: 1px solid #ddd;
    }
    .apexcharts-xaxis-texts-g > text,
    .apexcharts-yaxis-texts-g > text {
        fill: #333;
    }
    .apexcharts-legend-series > span.apexcharts-legend-text {
        color: #333 !important;
    }
}
.graphContainer{margin-top: 16px; background: #fff !important;}
#report .box > li{padding: 32px 24px !important;}
#report .box > li.risserArea{margin-top: 40px;}
::v-deep #report  p.date{margin-bottom: 10px; width: 100%; text-align: center;}
#report .styleTit{color:var(--color-point); margin-bottom: 16px;}
#report .styleTit::before{background:var(--color-point);}
.flexRows{display: flex; align-items: flex-start; justify-content: space-between; width: 100%; gap: 24px;}
.flexRows .cols {width: calc(100% - 16px); margin-bottom: 40px;}
.flexRows .cols.cobbImgCols{ width: 100%;  height: 405px; background: #444; position: relative;}
.flexRows .cols.cobbImgCols img{position: absolute; max-width: 100%; max-height: 100%; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.flexRows .cols.infoCols .tblBg2{width: 100%; margin-bottom: 24px;}
.flexRows .cols.infoCols p.graphTitle{margin-bottom: 10px !important;}
.flexRows .cols.infoCols .risserResultCols li{margin-bottom: 24px;}
.flexRows .cols.infoCols .risserResultCols li .resultValue{background: #fff; width: 100%; text-align: center; padding: 10px 16px; border-radius: 4px; color:var(--color-point);}
.risserImgCols {
    display: flex;
    justify-content: center;
    align-items: center;
}

.risserImgCols img {
    max-width: 75%;
    height: auto;
}

</style>
